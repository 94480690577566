exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---template-cache-tmp-contact-js": () => import("./../../../.template-cache/tmp-contact.js" /* webpackChunkName: "component---template-cache-tmp-contact-js" */),
  "component---template-cache-tmp-datenschutz-js": () => import("./../../../.template-cache/tmp-datenschutz.js" /* webpackChunkName: "component---template-cache-tmp-datenschutz-js" */),
  "component---template-cache-tmp-dishwasher-tabs-js": () => import("./../../../.template-cache/tmp-dishwasher-tabs.js" /* webpackChunkName: "component---template-cache-tmp-dishwasher-tabs-js" */),
  "component---template-cache-tmp-geschirrspueltabs-js": () => import("./../../../.template-cache/tmp-geschirrspueltabs.js" /* webpackChunkName: "component---template-cache-tmp-geschirrspueltabs-js" */),
  "component---template-cache-tmp-gruene-hexe-js": () => import("./../../../.template-cache/tmp-gruene-hexe.js" /* webpackChunkName: "component---template-cache-tmp-gruene-hexe-js" */),
  "component---template-cache-tmp-home-js": () => import("./../../../.template-cache/tmp-home.js" /* webpackChunkName: "component---template-cache-tmp-home-js" */),
  "component---template-cache-tmp-impressum-js": () => import("./../../../.template-cache/tmp-impressum.js" /* webpackChunkName: "component---template-cache-tmp-impressum-js" */),
  "component---template-cache-tmp-imprint-js": () => import("./../../../.template-cache/tmp-imprint.js" /* webpackChunkName: "component---template-cache-tmp-imprint-js" */),
  "component---template-cache-tmp-kontakt-js": () => import("./../../../.template-cache/tmp-kontakt.js" /* webpackChunkName: "component---template-cache-tmp-kontakt-js" */),
  "component---template-cache-tmp-linktree-js": () => import("./../../../.template-cache/tmp-linktree.js" /* webpackChunkName: "component---template-cache-tmp-linktree-js" */),
  "component---template-cache-tmp-privacy-policy-js": () => import("./../../../.template-cache/tmp-privacy-policy.js" /* webpackChunkName: "component---template-cache-tmp-privacy-policy-js" */),
  "component---template-cache-tmp-waschpulver-js": () => import("./../../../.template-cache/tmp-waschpulver.js" /* webpackChunkName: "component---template-cache-tmp-waschpulver-js" */),
  "component---template-cache-tmp-washing-powder-js": () => import("./../../../.template-cache/tmp-washing-powder.js" /* webpackChunkName: "component---template-cache-tmp-washing-powder-js" */)
}




export default {
        text: "#383E42",
        primary: "#1C6635",
        light: "#209B66",
        lighter: "#96E596",

        variant: "#DDF0DD",
        white: "#fff",
        black: "#000",
    
        alert: "red",
};
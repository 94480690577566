module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://gruene-hexe.studiojersch.de/graphql","type":{"MediaItem":{"localFile":{"requestConcurrency":20,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"3","matomoUrl":"//matomo.productable.de","siteUrl":"https://gruenehexe.de/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"theme_color_in_head":false,"icon":"src/images/gh-fav-512-g.png","icons":[{"src":"src/images/gh-fav-192-g.png","sizes":"192x192","type":"image/png"},{"src":"src/images/gh-fav-512-g.png","sizes":"512x512","type":"image/png"}],"start_url":"/","lang":"de","name":"Grüne Hexe","short_name":"Grüne Hexe","description":"Bio. Logisch. Sauber.","background_color":"#1C6635","theme_color":"#fff","localize":[{"start_url":"/en/","lang":"en","name":"Grüne Hexe","short_name":"Grüne Hexe","description":"Eco. Logical. Clean."}],"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b7d56515c81a14cb660499b5615cde22"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

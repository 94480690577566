import merge from 'lodash/merge';
import baseTheme from './theme';
import colors from './colors';
import styles from './components-styles';
import "../components/layout.css"


export default merge({}, baseTheme, {
  colors,
  fonts: {
    body: 'Overpass, system-ui, sans-serif',
    heading: `Akrobat, sans-serif`,
    monospace: `Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace`,
  },
  styles,
});
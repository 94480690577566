let headings = {}
for (let num = 1; num <= 6; num++) {
  headings[`h${num}`] = { variant: `styles.h${num}` }
}

export default {
  root: {
    fontFamily: "body",
    fontSize: 1,
    fontWeight: "body",
    lineHeight: "body",
  },


  SourcedImage: {
    variant: "styles.img",
    mx: "auto",
  },
  SourcedContent: {

    ...headings,

    p: {
      variant: "styles.p",
      color: "text",
      wordBreak: "break-word",

    },
    h1: {
      variant: "styles.h1",
      fontSize: [4,4,5],
      color:`primary`,
      wordBreak: "break-word",
    },
    h2: {
      variant: "styles.h2",
      fontSize: [3,3,4],
      //color:`primary`
    },

    h3: {
      variant: "styles.h3",
      fontSize: [2,2,3],
      color:`primary`
    },
    a: {
      variant: "styles.a",
    },
    ".gallery": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      ml: "-23px",
      mr: "-23px",
    },

    ".gallery-item": {
      mx: 3,
      my: 3,
    },
  },
  CategoryItem: {
    textDecoration: `none`,
    color: `text`,
    "&:hover": {
      color: "primary",
    },
    "&:not(:last-child)": {
      mr: 1,
    },
    "&:not(:last-child):after": {
      content: '" ~ "',
      ml: 1,
    },
  },
}